<script setup></script>
<template>
	<main>
		<h1 class="title">14 Years of Unmatched Excellence: Haier's Dominance in the Global Major Appliances Market</h1>
		<p>Haier is a global leader in the home appliances market, delivering innovative products to people around the world. Since its establishment in 1984, Haier has built a reputation for excellence in design, manufacturing, and customer service. Haier's commitment to innovation, quality, and customer satisfaction has earned it the distinction of being ranked the No.1 Global Major Appliances Brand for 14 consecutive years.</p>
		<p>
			<img src="@/assets/images/news1.jpg" alt="" />
		</p>
		<p>Haier's product portfolio includes Haier Inverter refrigerators, washing machines, air conditioners, Smart LED TVs, Kitchen and other home appliances. The company's focus on innovative design, high-quality manufacturing, and superior customer service has made it a household name in many countries around the world. With a presence in over 100 countries and regions, Haier has established itself as a reliable and trusted brand in the global home appliances market.</p>
		<p>One of Haier's core values is customer-centricity, which is reflected in the company's approach to product development and service delivery. Haier's products are designed with the needs and preferences of customers in mind, ensuring that they meet the highest standards of quality and performance. Haier also offers after-sales services, providing timely and effective support to customers in need on daily basis.</p>
		<p>Haier's commitment to innovation is another key driver of its success. The company invests heavily in research and development, constantly seeking new ways to improve its products and services.</p>
		<p>In addition to its commitment to innovation and quality, Haier is also an eco-friendly brand that focuses on energy efficiency. Many of Haier's products are designed with features that minimize energy consumption and reduce their environmental impact. For example, Haier's refrigerators are equipped with inverter compressors that adjust their power consumption based on usage, helping to save energy and reduce electricity bills.</p>
		<p>The brand also offers a range of Inverter air conditioners that use environment friendly refrigerants and employ smart technologies to optimize cooling efficiency. By prioritizing energy efficiency and sustainability in its product development, Haier is helping to make people's lives better while also contributing to a healthier planet.</p>
		<p>
			Haier's consistent ranking as the No.1 Global Major Appliances Brand for 14 consecutive years is a testament to the company's commitment to excellence in all aspects of its operations. From product design to manufacturing to customer service, Haier has set the standard for quality and innovation in the home appliances industry. With its continued focus on customer-centricity and innovation, Haier is well-positioned to maintain its leadership position in the global market for years to come.
		</p>
	</main>
</template>
<style scoped>
main {
	width: var(--minWidth);
	margin: 0 auto;
	padding: 0 160px;
}
.title {
	font-size: 30px;
	font-weight: 400;
}
p {
	font-size: 14px;
	margin: 50px 0;
}
p img {
	display: block;
	margin: 0 auto;
}
</style>
